<template>
  <div :id="id" :class="['m-number-plat-place card-spacing', isPC ? 'z-web' : 'z-phone', theme.primaryColor ? theme.primaryColor : '']">
    <div class="u-number-plat-place-conetnt">
      <VisualTitle :title="mainTlt" @visual="handleVisual" />
      <div v-if="isPC" class="u-content-wrap">
        <p class="u-content-tlt">
          <VisualItem :visualKeys="tlt.visualKeys" @visual="handleVisual">
            {{ tlt.value }}
          </VisualItem>
        </p>
        <p class="u-content-desc">
          <VisualItem :visualKeys="desc.visualKeys" @visual="handleVisual">
            {{ desc.value }}
          </VisualItem>
        </p>
        <div class="u-service-list">
          <VisualItem :visualKeys="list.visualKeys" @visual="handleVisual">
            <div
              v-for="(item, index) in list.value"
              :key="`service-${index}`"
              class="m-service"
            >
              <VisualItem :visualKeys="[item.img.visualKeys, item.img_2x.visualKeys]" @visual="handleVisual">
                <CMSImage
                  :img="item.img.value"
                  :img2x="item.img_2x.value"
                  class-name="u-img"
                />
              </VisualItem>
              <p class="u-title">
                <!-- visualKeys 组合的key 用于获取节点数据、更新节点等操作 -->
                <VisualItem :visualKeys="item.title.visualKeys" @visual="handleVisual">
                  {{ item.title.value }}
                </VisualItem>
              </p>
              <p class="u-desc">
                <VisualItem :visualKeys="item.desc.visualKeys" @visual="handleVisual">
                  {{ item.desc.value }}
                </VisualItem>
              </p>
            </div>
          </VisualItem>
        </div>
        <div v-if="moreLinkText && moreLinkText.value" class="u-more">
          <VisualItem :visualKeys="[moreLinkText.visualKeys, moreLink.visualKeys]" @visual="handleVisual">
            <CMSLink
              :link="moreLink.value"
              class-name="more-btn"
            >
              {{ moreLinkText.value }}
            </CMSLink>
          </VisualItem>
        </div>
      </div>
      <!-- 移动端 -->
      <div v-else class="u-content-wrap">
        <div class="u-content">
          <p class="u-content-tlt">
            <VisualItem :visualKeys="tlt.visualKeys" @visual="handleVisual">
              {{ tlt.value }}
            </VisualItem>
          </p>
          <p class="u-content-desc">
            <VisualItem :visualKeys="desc.visualKeys" @visual="handleVisual">
              {{ desc.value }}
            </VisualItem>
          </p>
        </div>
        <div class="u-service-list">
          <VisualItem :visualKeys="list.visualKeys" @visual="handleVisual" tip="编辑列表">
            <div
              v-for="(item, index) in list.value"
              :key="`service-${index}`"
              class="m-service"
            >
              <VisualItem :visualKeys="[item.img_2x_h5.visualKeys, item.img_3x_h5.visualKeys]" @visual="handleVisual">
                <CMSImage
                  :img="item.img_2x_h5.value ? item.img_2x_h5.value : item.img.value"
                  :img2x="item.img_3x_h5.value ? item.img_3x_h5.value : item.img_2x.value"
                  class-name="u-img"
                />
              </VisualItem>
              <VisualItem :visualKeys="[item.img_2x_h5.visualKeys, item.img_3x_h5.visualKeys]" @visual="handleVisual" class="u-card-content">
                <p class="u-card-title">
                  <!-- visualKeys 组合的key 用于获取节点数据、更新节点等操作 -->
                  <VisualItem :visualKeys="item.title.visualKeys" @visual="handleVisual">
                    {{ item.title.value }}
                  </VisualItem>
                </p>
                <p class="u-card-desc">
                  <VisualItem :visualKeys="item.desc.visualKeys" @visual="handleVisual">
                    {{ item.desc.value }}
                  </VisualItem>
                </p>
              </VisualItem>
            </div>
          </VisualItem>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import config from './config.json'
import Load from '~/assets/js/load.js'
import { fixConfig } from '~/assets/js/visualFix'
import CMSImage from '~/components/common/Image'
import CMSLink from '~/components/common/Link'
import VisualItem from '~/components/common/VisualItem'
import VisualTitle from '~/components/common/VisualTitle'

const componentKey = 'NumberPlatPlace'

export default {
  components: {
    CMSImage,
    CMSLink,
    VisualItem,
    VisualTitle
  },
  props: {
    id: {
      type: [String, Number],
      default: 'NumberPlatPlace'
    },
    mode: {
      type: String,
      default: ''
    },
    reqData: {
      type: Object,
      default() {
        return {}
      }
    },
    isPC: {
      type: Boolean,
      default: true
    }
  },
  data() {
    const data = fixConfig(_.cloneDeep(config[componentKey]), this.mode === 'dev' ? {
      id: componentKey,
      reqData: Load(config, componentKey)
    } : this.$store.getters.getComponent(this.id))

    this.$store.commit('updateComponentById', {
      id: this.id || componentKey,
      data
    })

    return {
      ...data
    }
  },
  computed: {
    theme() {
      return this.$store.state.theme
    }
  },
  methods: {
    handleVisual(obj) {
      this.$emit('visual', obj)
    }
  }
}
</script>

<style lang="postcss">
@import '~/assets/css/4mixin.css';

.m-number-plat-place {
  &.z-phone {
    background-color: #f7f8fa;
    padding: 0 pxtorem(40);

    .u-content-wrap {
      .u-content {
        width: 100%;
        padding: pxtorem(36) pxtorem(40) pxtorem(60);
        background: #fff;
        box-shadow: 0 pxtorem(8) pxtorem(40) rgba(47, 56, 111, 0.156305);
      }

      .u-content-tlt {
        line-height: pxtorem(52);
        font-size: pxtorem(36);
        color: #333;
        font-weight: bold;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .u-content-desc {
        line-height: pxtorem(52);
        font-size: pxtorem(26);
        color: #666;
        margin-top: pxtorem(30);
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 7;
        -webkit-box-orient: vertical;
      }

      .u-service-list {
        .m-service {
          width: 100%;
          height: pxtorem(508);
          position: relative;

          .u-img {
            width: 100%;
            height: pxtorem(508);
          }

          &:nth-child(1) > .u-card-content::before {
            width: 0;
            height: 0;
            border-left: pxtorem(20) solid transparent;
            border-right: pxtorem(20) solid transparent;
            border-bottom: pxtorem(20) solid #181c23;
            content: '';
            position: absolute;
            top: pxtorem(-20);
            left: pxtorem(314);
          }

          .u-card-content {
            position: absolute;
            bottom: 0;
            width: 100%;
            height: 100%;
            padding: pxtorem(136) pxtorem(42) pxtorem(40);
            background: rgba(0, 0, 0, 0.7);

            .u-card-title {
              line-height: pxtorem(50);
              font-size: pxtorem(36);
              margin-bottom: pxtorem(30);
              color: #fff;
              font-weight: bold;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            .u-card-desc {
              line-height: pxtorem(50);
              font-size: pxtorem(26);
              color: #fff;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 5;
              -webkit-box-orient: vertical;
            }
          }
        }
      }
    }
  }

  &.z-web {
    background-color: #fff;
    /* min-height: 793px; */

    .u-number-plat-place-conetnt {
      min-width: 1200px;
      max-width: 1432px;
      margin: 0 auto;
    }

    .u-content-wrap {
      /* padding-bottom: 60px; */
      box-sizing: border-box;
      text-align: left;
      background-color: #fff;

      .u-content-tlt {
        width: 1190px;
        margin: 60px auto 0;
        font-size: 26px;
        line-height: 34px;
        color: #333;
        position: relative;
      }

      .u-content-desc {
        width: 1190px;
        margin: 22px auto 0;
        line-height: 24px;
        color: #666;
        font-size: 14px;
      }
    }

    .u-service-list {
      width: 1200px;
      margin: 40px auto 0;

      .m-service {
        display: inline-block;
        width: 285px;
        height: 412px;
        vertical-align: top;
        margin-right: 20px;
        cursor: pointer;
        position: relative;
        font-size: 0;
        overflow: hidden;
        border: 1px solid #ebebeb;
        box-shadow: 0 4px 24px rgba(64, 80, 128, 0.08);
        transition: 0.3s;

        &:hover {
          box-shadow: 0 4px 24px rgba(64, 80, 128, 0.24);
        }

        &:nth-child(4n) {
          margin: 0;
        }

        .m-visual-item {
          width: 100%;
        }

        .u-img {
          display: block;
          width: 285px;
          height: 216px;
        }

        .u-title {
          font-size: 20px;
          width: 100%;
          height: 28px;
          line-height: 34px;
          color: #333;
          margin-top: 20px;
          margin-bottom: 21px;
          padding: 0 24px;
          position: relative;
        }

        .u-desc {
          color: #666;
          font-size: 14px;
          /* font-weight: 100; */
          line-height: 25px;
          height: 101px;
          padding: 0 24px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 4;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }

    .u-more {
      display: block;
      text-align: center;

      .more-btn {
        display: block;
        width: 132px;
        height: 40px;
        font-size: 14px;
        color: #fff;
        text-align: center;
        position: relative;
        background-color: $baseBlue;
        margin: 50px auto 0;
        padding-top: 7px;
        line-height: 28px;
      }

      .more-btn:hover::before {
        display: block;
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 5%);
      }

      .u-btn:active::before {
        display: block;
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 10%);
      }
    }

    &.red {
      .u-more .more-btn,
      .u-content-wrap .u-content-tlt::after,
      .u-service-list .m-service .u-title::after {
        background-color: $baseRed;
      }
    }
  }
}
</style>
